<template>
    <div style="width: 100%;padding-top: 20px;">
        <div class="info" style="margin-top:0">
            <div class="info-title">
                <span class="info-title-icon"></span>
                <span class="info-title-name"> 基础信息</span>
            </div>
            <div class="info-detail">
                <div class="info-detail-line" v-for="(item,index) in baseinfo" :key="index">
                    <div class="info-detail-line-left">{{item.title}}：</div>
                    <div class="info-detail-line-right" :title="totalData[item.value]">{{ totalData[item.value] }}</div>
                </div>
            </div>
        </div>
        <div class="info">
            <div class="info-title">
                <span class="info-title-icon"></span>
                <span class="info-title-name"> 产品信息</span>
            </div>
            <div class="info-detail">
                <div class="info-detail-line" v-for="(item,index) in productinfo" :key="index">
                    <div class="info-detail-line-left">{{item.title}}：</div>
                    <div class="info-detail-line-right" :title="totalData[item.value]">{{ totalData[item.value] }}</div>
                </div>
            </div>
        </div>

        <div style="display: flex;justify-content: space-between">
            <div class="info" style="width: 49%">
                <div class="info-title">
                    <span class="info-title-icon"></span>
                    <span class="info-title-name">财务信息</span>
                </div>
                <div class="info-detail">
                    <div class="info-detail-line" style="display: flex;justify-content: space-between">
                        <div class="info-detail-line-left">
                            <div>{{new Date().getFullYear()- 2}}年度销售收入:<span style="color: #333;">--</span></div>
                            <div>年度净资产总额:<span style="color: #333;">--</span></div>
                            <div>年度研发投入总额:<span style="color: #333;">--</span></div>
                        </div>
                        <div class="info-detail-line-left">
                            <div>{{new Date().getFullYear()- 3}}年度销售收入:<span style="color: #333;">--</span></div>
                            <div>年度净资产总额:<span style="color: #333;">--</span></div>
                            <div>年度研发投入总额:<span style="color: #333;">--</span></div>
                        </div>
                        <div class="info-detail-line-left">
                            <div>{{new Date().getFullYear()- 4}}年度销售收入:<span style="color: #333;">--</span></div>
                            <div>年度净资产总额:<span style="color: #333;">--</span></div>
                            <div>年度研发投入总额:<span style="color: #333;">--</span></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="info" style="width: 49%">
                <div class="info-title">
                    <span class="info-title-icon"></span>
                    <span class="info-title-name"> 知识产权信息</span>
                </div>
                <div class="info-detail">
                    <div class="info-detail-line" style="display: flex;">
                        <div class="info-detail-line-left">
                            <div>企业专利总数：<span style="color: #333;">{{ totalData.patentTotal }}</span></div>
                            <div>发明类专利:<span style="color: #333;">{{ totalData.inventionPatents }}</span></div>
                        </div>
                        <div class="info-detail-line-left" style="margin-left: 150px">
                            <div>实用型专利:<span style="color: #333;">{{ totalData.appliedPatents }}</span></div>
                            <div>外观设计专利:<span style="color: #333;">{{ totalData.designPatents }}</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {getEnterpriseInfo} from "../../../plugins/api/EnterpriseManagementCenter";
    import {mapGetters} from 'vuex'
    import {errorToast} from "../../../plugins/tools/util";

    export default {
        name: "EnterPriseInfo",
        data() {
            return {
                totalData: {
                    enterpriseName: "",
                    enterpriseType: "",
                    designPatents: "",
                    appliedPatents: "",
                    inventionPatents: "",
                    patentTotal: "",
                    productDescribe: "",
                },
                baseinfo: [
                    {title: '企业名称', value: 'enterpriseName'},
                    {title: '企业类型', value: 'enterpriseType'},
                    {title: '法定代表人', value: 'legalRepresentative'},
                    {title: '所属行业', value: 'involved'},
                    {title: '行政区划', value: 'administrativeDivision'},
                    {title: '营业期限', value: 'businessTerm'},
                    {title: '企业地址', value: 'site'},
                    {title: '员工数量', value: 'staffTotal'},
                    {title: '注册资本金', value: 'registeredCapital'},
                    {title: '所属科技领域', value: 'satTerritory'},
                    {title: '经营范围', value: 'businessScope'}
                ],
                productinfo: [
                    {title: '主营产品名称', value: 'productName'},
                    {title: '主营产品标签', value: 'productLabel'},
                    {title: '主营产品技术领域', value: 'mptName'},
                    {title: '产品获奖情况', value: 'prizeWinning'},
                    {title: '主营产品描述', value: 'productDescribe'}
                ],
            };
        },
        computed: {
            ...mapGetters({
                token: "userStore/token",
                userName: "userStore/userName",
                userId: "userStore/userId",
                mbrMemberId: "userStore/mbrMemberId",
            }),
        },
        mounted() {
            this.getInfo();
        },
        methods: {
            async getInfo() {
                let params = {
                    mbrMemberId: this.mbrMemberId,
                };
                const json = await getEnterpriseInfo(params);
                if (json.code == 0) {
                    this.totalData = json.result;
                    for (let item in this.totalData) {
                        if (this.totalData[item] == null) {
                            this.totalData[item] = '--'
                        }
                    }
                    this.$parent.changeHeight();
                } else {
                    errorToast(json.message)
                }

            },
        },
    };
</script>

<style scoped lang="scss">
    .info {
        background: #fff;
        border-radius: 10px;
        padding: 15px 20px;
        margin: 20px;
    }

    .info-title {
        display: flex;
        align-items: center;
    }

    .info-title-icon {
        width: 6px;
        height: 16px;
        background: #1767E0;
    }

    .info-title-name {
        font-size: 16px;
        font-weight: bold;
        color: #333333;
        margin-left: 20px;
    }

    .info-detail {
        padding: 0 30px;
    }

    .info-detail-line {
        display: flex;
        font-size: 14px;
        line-height: 30px;
    }

    .info-detail-line-left {
        color: #666;
        flex-shrink: 0;
    }

    .info-detail-line-right {
        color: #333;
        max-width: 900px;
    }
</style>
